/* eslint-disable no-undef */
import React from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import pageHeader from './../images/page-header-bg.jpeg';
import sectionBG from './../images/section-bg01.png';

const contact = () => {
  function handleOnSubmit(e) {
    e.preventDefault();
    emailjs.sendForm('service_57oxlmj', 'template_5372ny2', e.target, 'user_lU5krpCD50Avo8LIAaJIT').then(
      result => {
        console.log(result.text);
        Swal.fire('Email Sent!', 'we will get back to you shortly!', 'success');
      },
      error => {
        console.log(error.text);
        Swal.fire('Email Failed!', 'Please try again later!', 'error');
      }
    );
    e.target.reset();
  }

  return (
    <Layout>
      <Seo title="Contact Us" description="We are here, Our door is always open for a good cup of coffee!" />
      <header className="page-header" data-background={pageHeader} data-stellar-background-ratio="0.7">
        <div className="container">
          <h2>Contact</h2>
          <p>Wild flowers, plants and fungi are the life support</p>
        </div>
        <div className="parallax-element" data-stellar-ratio="2"></div>
      </header>

      <section className="content-section no-bottom-spacing" data-background={sectionBG} data-stellar-background-ratio="1.2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-left">
                <h6>Here&lsquo;s how you can get in touch</h6>
                <h2>
                  Whether you&lsquo;re a current Countryside <br />
                  member or are thinking about joining <br />
                  us, have a question.
                </h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form">
                <form onSubmit={handleOnSubmit}>
                  <div className="form-group">
                    <input name="name" type="text" placeholder="Complete Name" />
                  </div>
                  <div className="form-group">
                    <input name="email" type="text" placeholder="E-mail Address" />
                  </div>
                  <div className="form-group">
                    <input name="subject" type="text" placeholder="Subject" />
                  </div>
                  <div className="form-group">
                    <textarea name="message" placeholder="Message"></textarea>
                  </div>
                  <div className="form-group">
                    <input type="submit" value="SEND MESSAGE" />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-box">
                <figure>
                  <StaticImage src="../images/contact-icon01.png" alt="Image" />
                </figure>
                <div className="content">
                  <h6>Waiting Your Call!</h6>
                  <p>+27 72-786-0001</p>
                </div>
              </div>
              <div className="contact-box">
                <figure>
                  <StaticImage src="../images/contact-icon02.png" alt="Image" />
                </figure>
                <div className="content">
                  <h6>Email the editor</h6>
                  <p>info@kapeefarms.co.za</p>
                </div>
              </div>
              <div className="contact-box">
                <figure>
                  <StaticImage src="../images/contact-icon03.png" alt="Image" />
                </figure>
                <div className="content">
                  <h6>Registered office at</h6>
                  <p>Prasper Solutions Ground Floor Southdowns Ridge Office Park, Cnr John Vorster & Nellmapius Drive Irene Centurion 0157.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="google-maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3589.5615741499455!2d28.2006831159143!3d-25.883903383581487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9513bd905021a1%3A0x967e232c48fa2012!2sPSOL!5e0!3m2!1sen!2sin!4v1663047154754!5m2!1sen!2sin" loading="lazy" referrerPolicy="no-referrer-when-downgrade" frameBorder="0" allowFullScreen="" aria-hidden="false"></iframe>
      </div>
    </Layout>
  );
};

export default contact;
